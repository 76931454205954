<template>
    <div class="input-lable-container">
      <a class="inline-image of-16x16">
        <img :src="getImgUrl(icon)"  />
      </a>
      <span class="new-lable-container">{{ lableName }} :</span>
      <input
                        class="input qbinput"
                        type="text"
                        v-model="inputValue"
                        
                        @input="updateValue"
                        
                        :disabled="isDisable"
                      />
    </div>
  </template>
  
  <script>
  export default {
    props: {
      lableName: {
        type: String,
        default: null,
      },
      lableValue: {
        default: "NA",
      },
      icon: {
        type: String,
        default: "NA",
      },
      isDisable:{
        type: Boolean,
        default: false
      }
    },
    data() {
    return {
      inputValue: '',
      altimg : 'Not Fount'
    };
    },
    methods: {
      getImgUrl(img) {
      
          return require("../../src/assets/icon_svg/" + img);
        
      },
      updateValue() {
      this.$emit('input', this.inputValue);
    }
    },
    
  };
  </script>
  <style lang="scss">
  .input-lable-container{
    align-items: center;
    display: flex;
    gap: 7px;
    border-radius: 15px;
    padding: 1px 10px;
    border: 1px solid #ededed;
  }

  .new-lable-container {
    font-weight: 700;
    /* width: 20%; */
    max-width: fit-content;
    display: inline-block;
    white-space: nowrap;
  }
  </style>
  